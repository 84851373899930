<template>
  <div class="wrapper">
    <Crumb :title.sync="title" :menu.sync="menu" />
    <Banner :img.sync="img" />
    <div class="container">
      <div class="content">
        <div class="title">
          <span>02</span>
          <h3>加入凯达</h3>
          <p>Join Us</p>
        </div>
        <ul class="jobs-list clearfix">
          <li
            v-for="(item, index) in jobsList"
            :key="index"
            @click="go(item.url)"
          >
            <img :src="item.img" />
            <p>{{ item.text }}</p>
          </li>
        </ul>
        <div class="job-demand">
          <div class="name">
            <h3>POSITION REQUIREMENTS</h3>
            <h4><span>岗位需求</span></h4>
          </div>
          <ul class="clearfix">
            <li v-for="(item, index) in demandList" :key="index">
              <div class="flip">
                <div class="front">
                  <img :src="item.img" />
                  <div v-html="item.front"></div>
                </div>
                <div class="back">
                  <div v-html="item.back"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="job-resume" @click="download()">
          <span class="iconfont icon-document"></span>
          <p>简历模板</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Crumb from "@/components/Crumb.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: { Crumb, Banner },
  data() {
    return {
      title: "",
      menu: [],
      img: require("@/assets/images/banner3.jpg"),
      jobsList: [
        {
          text: "社会招聘",
          img: require("@/assets/images/icon-jobs-01.png"),
          url: "https://s2ngewehvzjp9e2kq.icloudportal.com/cms/recruitment/recruitment_joblist?batchtypeid=pivTFzqwpi8dsYGvB&location=5ongPPN9WzMuNQzEp",
        },
        {
          text: "校园招聘",
          img: require("@/assets/images/icon-jobs-02.png"),
          url: "https://s2ngewehvzjp9e2kq.icloudportal.com/cms/recruitment/recruitment_joblist?batchtypeid=5Gj6Sjk7EYc8R2Xen&location=5ongPPN9WzMuNQzEp",
        },
        {
          text: "实习生招聘",
          img: require("@/assets/images/icon-jobs-03.png"),
          url: "https://s2ngewehvzjp9e2kq.icloudportal.com/cms/recruitment/recruitment_joblist?batchtypeid=xTwNPeTRrpexJ2Bw3&location=5ongPPN9WzMuNQzEp",
        },
      ],
      demandList: [
        {
          img: require("@/assets/images/icon-jobs-04.png"),
          front:
            "<h3>管理类</h3><p>人力总监 | 财务总监<br/>总经理办公室主任</p>",
          back: "<h3>需求专业：</h3><p>人力资源管理、财务管理、金融类、企业管理、经营管理等相关专业</p>",
        },
        {
          img: require("@/assets/images/icon-jobs-05.png"),
          front: "<h3>运营类</h3><p>项目经理 | 营销策划</p>",
          back: "<h3>需求专业：</h3><p>项目管理、经济管理，市场运营、市场营销、金融等相关专业</p>",
        },
        {
          img: require("@/assets/images/icon-jobs-06.png"),
          front:
            "<h3>工程类</h3><p>项目经理 | 外籍咨询<br/>成本管理 | 设计管理</p>",
          back: "<h3>需求专业：</h3><p>土木工程、工程造价、水暖工程、暖通工程、电气工程、电气自动化、规划类、建筑学等相关专业</p>",
        },
        {
          img: require("@/assets/images/icon-jobs-07.png"),
          front: "<h3>财务类</h3><p>财务总监 | 会计 | 出纳</p>",
          back: "<h3>需求专业：</h3><p>财务管理、会计类，金融类等相关专业</p>",
        },
        {
          img: require("@/assets/images/icon-jobs-08.png"),
          front: "<h3>综合类</h3><p>文字综合 | 法务专员<br/>行政后勤</p>",
          back: "<h3>需求专业：</h3><p>人力资源管理、新闻、中文、文秘、法学等相关专业</p>",
        },
        {
          img: require("@/assets/images/icon-jobs-09.png"),
          front: "<h3>实习生</h3><p>运营类 | 工程类<br/>财务类 | 综合类</p>",
          back: "<h3>需求专业：</h3><p>运营类、工程类、财务类、综合涉及的相关专业在校生</p>",
        },
      ],
    };
  },
  methods: {
    go(url) {
      alert("投递简历前，请点击右下角【简历模板】下载申请表，如已下载请忽略。");
      window.open(url, "_blank");
    },
    download() {
      let detail = this.$router.resolve({ path: "/document/应聘申请表.docx" });
      window.open(detail.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.wrapper {
  .container {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    padding: 30px 0;
    .content {
      color: #666;
      .title {
        background: url(../../assets/images/bg-title.png) no-repeat 0 0;
        width: 255px;
        height: 50px;
        color: #fff;
        margin-bottom: 20px;
        padding: 15px 20px;
        span {
          display: block;
          float: left;
          width: 50px;
          height: 50px;
          font-size: 24px;
          color: #eb2737;
          text-align: center;
          font-weight: bold;
          line-height: 50px;
          margin-right: 10px;
        }
        h3 {
          font-size: 20px;
          line-height: 30px;
          font-weight: normal;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
      .jobs-list {
        padding: 30px 60px;
        li {
          float: left;
          background: #f6faff;
          width: 140px;
          text-align: center;
          margin: 10px 10px;
          padding: 80px 100px;
          cursor: pointer;
          img {
            margin-bottom: 10px;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
      .job-demand {
        padding: 30px 0 60px;
        .name {
          text-align: center;
          margin-bottom: 40px;
          h3 {
            font-size: 32px;
            font-weight: normal;
            color: #cf281a;
            line-height: 40px;
          }
          h4 {
            font-size: 16px;
            color: #cf281a;
            line-height: 40px;
            padding-top: 10px;
            span {
              padding-top: 10px;
              border-top: 1px solid #cf281a;
            }
          }
        }
        ul {
          padding: 0 60px;
          li {
            float: left;
            width: 340px;
            height: 340px;
            margin: 10px 10px;
            perspective: 1000;
            -webkit-perspective: 1000;
            -ms-perspective: 1000;
            transform-style: preserve-3d;
            -ms-transform-style: preserve-3d;
            .flip {
              position: relative;
              width: 100%;
              height: 100%;
              transition: 0.6s;
              perspective: 1000;
              -webkit-perspective: 1000;
              -ms-perspective: 1000;
              transform-style: preserve-3d;
              -ms-transform-style: preserve-3d;
              .front {
                position: absolute;
                top: 0;
                left: 0;
                background: #fff;
                width: 200px;
                height: 200px;
                text-align: center;
                padding: 70px 70px;
                box-shadow: 0px 0px 20px #dbdbdb;
                z-index: 2;
                // backface-visibility: hidden;
                img {
                  width: 80px;
                  height: 80px;
                  margin-bottom: 10px;
                }
                /deep/ h3 {
                  font-size: 18px;
                  margin-bottom: 10px;
                }
              }
              .back {
                position: absolute;
                top: 0;
                left: 0;
                background: #cf281a;
                width: 200px;
                height: 200px;
                color: #fff;
                padding: 70px 70px;
                transform: rotateY(180deg);
                -ms-transform: rotateY(180deg);
                -moz-transform: rotateY(180deg);
                // backface-visibility: hidden;
                /deep/ h3 {
                  font-size: 18px;
                  margin-bottom: 20px;
                }
              }
            }
          }
          li:hover {
            .flip {
              transform: rotateY(180deg);
              -ms-transform: rotateY(180deg);
              -moz-transform: rotateY(180deg);
            }
            .back {
              z-index: 3;
            }
          }
        }
      }
      .job-resume {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        right: 40px;
        bottom: 40px;
        background: #325ea0;
        width: 60px;
        height: 60px;
        font-size: 14px;
        color: #fff;
        padding: 10px 10px;
        border-radius: 40px;
        cursor: pointer;
        .iconfont {
          display: block;
          font-size: 40px;
        }
      }
    }
  }
}
</style>
